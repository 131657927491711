<template>
  <div class="popup_wrap" style="width:1050px; height: 300px;">
    <button class="layer_close" @click="$emit('close')">close</button>

    <div class="popup_cont">
      <h1 class="page_title">{{ $t('msg.ONEX050P081.002') }}</h1> <!-- B/L D/G Entry -->

      <div class="content_box">
        <table class="tbl_col">
          <colgroup>
            <col style="width:10%">
            <col style="width:22%">
            <col style="width:22%">
            <col style="width:22%">
            <col style="width:24%">
          </colgroup>
          <thead>
            <tr>
              <th scope="col">{{ $t('msg.CSBK100.287') }}</th> <!-- SEQ -->
              <th scope="col">{{ $t('msg.CSBK100.272') }}</th> <!-- Size -->
              <th scope="col">{{ $t('msg.CSBK100.273') }}</th> <!-- Type -->
              <th scope="col">{{ $t('msg.CSBK100.349') }}</th> <!-- UN No. -->
              <th scope="col">{{ $t('msg.CSBK100.378') }}</th> <!-- IMO Class -->
            </tr>
          </thead>
          <tbody v-if="resultList.length > 0">
            <tr v-for="(vo, idx) in resultList" :key="'cntrDg_' + idx">
              <td v-if="vo.dgSeq === '1'" :rowspan="vo.dgCnt">{{ vo.cntrSeq }}</td>
              <td v-if="vo.dgSeq === '1'" :rowspan="vo.dgCnt">{{ vo.cntrSzCdNm }}</td>
              <td v-if="vo.dgSeq === '1'" :rowspan="vo.dgCnt">{{ vo.cntrTypCd }}</td>
              <td class="td_border">{{ vo.imdunNo }}</td>
              <td>{{ vo.imoClasCd }}</td>
            </tr>
          </tbody>
          <tbody v-if="resultList.length === 0">
            <tr>
              <td colspan="5">{{ $t('msg.CMBA660.005') }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import commons from '@/api/services/commons'
import trans from '@/api/rest/trans/trans'

export default {
  name: 'BLContainerDGPop',
  props: {
    parentInfo: {
      type: Object,
      default () {
        return {
          bkgNo: ''
        }
      }
    }
  },
  data () {
    return {
      commonCodes: {},
      resultList: []
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      const arrCdId = ['01031', '01038']

      await commons.getCommonsCodesCommon(arrCdId).then(res => {
        this.commonCodes = res
      })

      const params = {
        bkgNo: this.parentInfo.bkgNo
      }

      await trans.findDGInfo(params).then(res => {
        const result = res.data

        if (res.headers.respcode === 'C0000' && result !== undefined) {
          const cntrSzList = this.commonCodes['01031']
          const cgoTypCdList = this.commonCodes['01038']

          result.forEach(c => {
            const cntrSzInfo = cntrSzList.filter(vo => vo.cd === c.cntrSzCd)
            if (cntrSzInfo !== undefined && cntrSzInfo.length > 0) {
              c.cntrSzCdNm = cntrSzInfo[0].cdNm
            }

            const cgoTypInfo = cgoTypCdList.filter(vo => vo.cd === c.cgoTypCd)
            if (cgoTypInfo !== undefined && cgoTypInfo.length > 0) {
              c.cgoTypCdNm = cgoTypInfo[0].cdNm
            }
          })

          this.resultList = result
        }
      })
    }
  }
}
</script>

<style>
  .td_border {border-left: 1px solid #e1e3eb !important;}
</style>
