var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap",
      staticStyle: { width: "1050px", height: "300px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.ONEX050P081.002"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content_box" }, [
          _c("table", { staticClass: "tbl_col" }, [
            _vm._m(0),
            _c("thead", [
              _c("tr", [
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.CSBK100.287"))),
                ]),
                _vm._v(" "),
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.CSBK100.272"))),
                ]),
                _vm._v(" "),
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.CSBK100.273"))),
                ]),
                _vm._v(" "),
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.CSBK100.349"))),
                ]),
                _vm._v(" "),
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.CSBK100.378"))),
                ]),
              ]),
            ]),
            _vm.resultList.length > 0
              ? _c(
                  "tbody",
                  _vm._l(_vm.resultList, function (vo, idx) {
                    return _c("tr", { key: "cntrDg_" + idx }, [
                      vo.dgSeq === "1"
                        ? _c("td", { attrs: { rowspan: vo.dgCnt } }, [
                            _vm._v(_vm._s(vo.cntrSeq)),
                          ])
                        : _vm._e(),
                      vo.dgSeq === "1"
                        ? _c("td", { attrs: { rowspan: vo.dgCnt } }, [
                            _vm._v(_vm._s(vo.cntrSzCdNm)),
                          ])
                        : _vm._e(),
                      vo.dgSeq === "1"
                        ? _c("td", { attrs: { rowspan: vo.dgCnt } }, [
                            _vm._v(_vm._s(vo.cntrTypCd)),
                          ])
                        : _vm._e(),
                      _c("td", { staticClass: "td_border" }, [
                        _vm._v(_vm._s(vo.imdunNo)),
                      ]),
                      _c("td", [_vm._v(_vm._s(vo.imoClasCd))]),
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _vm.resultList.length === 0
              ? _c("tbody", [
                  _c("tr", [
                    _c("td", { attrs: { colspan: "5" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CMBA660.005"))),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "22%" } }),
      _c("col", { staticStyle: { width: "22%" } }),
      _c("col", { staticStyle: { width: "22%" } }),
      _c("col", { staticStyle: { width: "24%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }